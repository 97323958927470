import React from 'react';
import styled from 'styled-components'
import css from '@styled-system/css'
import { Link } from "gatsby"
import { Grid, GridItem, Typography, Box } from '@mintuz/marvel'
import { GhostButton } from '../../components/GhostButton'
import { Layout } from '../../components/layout';
import { SEO } from '../../components/seo';
import { HeaderStyled, HeaderContentStyled } from '../../components/HeaderStyled';
import { Logo } from '../../components/Logo';
import { Pill } from '../../components/Pill';

import { useQueryParam, JsonParam } from "use-query-params";

const GridWrap = styled.div`
    max-width: 1100px;
    margin-left: auto;
    margin-right: auto;
    box-sizing: border-box;

    ${css({
        paddingLeft: [4],
        paddingRight: [4],
    })}
`

const GridWrapSmall = styled(GridWrap)`
    max-width: 500px;
`

const WorkoutList = styled(Grid)`
    list-style-type: none;
`

const ExerciseData = styled(GridItem)`
    td {
        text-align: center;
    }
`

const useRoutineData = () => {
    try {
        const [workoutDecoded] = useQueryParam("json", JsonParam);
        return workoutDecoded;
    } catch {
        return null
    }
}

function SharedPage() {
    const routineData = useRoutineData()

    if (!routineData) {
        return <Layout>
            <SEO enableSmartBanner={false} />
            <HeaderStyled>
                <GridWrap>
                    <Grid>
                        <GridItem width={[1 / 1]}>
                            <Box pt={[[4]]} display="flex" justifyContent="space-between" alignItems="center">
                                <Link to="/"><Logo /></Link>
                                <GhostButton as={Link} to="/workouts" variant="secondary">Free Workouts</GhostButton>
                            </Box>
                        </GridItem>
                        <GridItem width={[1 / 1]}>
                            <Box display="flex" justifyContent="center" alignItems="center" height="50vh">
                                <HeaderContentStyled color="white">
                                    <Typography as="h1" variant="displayOne" mb={[4]}>Invalid Workout</Typography>
                                </HeaderContentStyled>
                            </Box>
                        </GridItem>
                    </Grid>
                </GridWrap>
            </HeaderStyled>
            <Box pt="100px" pb={[6]}>
                <GridWrapSmall>
                </GridWrapSmall>
            </Box>
        </Layout>
    }

    return (
        <Layout>
            <HeaderStyled>
                <GridWrap>
                    <Grid>
                        <GridItem width={[1 / 1]}>
                            <Box pt={[[4]]} display="flex" justifyContent="space-between" alignItems="center">
                                <Link to="/"><Logo /></Link>
                                <GhostButton as={Link} to="/workouts" variant="secondary">Free Workouts</GhostButton>
                            </Box>
                        </GridItem>
                        <GridItem width={[1 / 1]}>
                            <Box display="flex" justifyContent="center" alignItems="center" height="50vh">
                                <HeaderContentStyled color="white">
                                    <Typography as="h1" variant="displayOne" mb={[4]}>{routineData.name}</Typography>
                                </HeaderContentStyled>
                            </Box>
                        </GridItem>
                    </Grid>
                </GridWrap>
            </HeaderStyled>
            <Box pt="100px" pb={[6]}>
                <GridWrapSmall>
                    <WorkoutList as="ul">
                        {
                            routineData.exercises && routineData.exercises.length > 0 && routineData.exercises.map((exercise) => {
                                return (
                                    <GridItem width={1 / 1}>
                                        <Box mb={[6]}>
                                            <Pill>
                                                <Typography as="h2" mt={[0]} variant="displayThree">
                                                    {exercise.name}
                                                </Typography>
                                                {
                                                    exercise.description &&
                                                        <Typography as="p" mt={[4]} variant="body">
                                                            {exercise.description}
                                                        </Typography>
                                                }
                                            </Pill>
                                        </Box>
                                    </GridItem>
                                );
                            })
                        }
                    </WorkoutList>
                </GridWrapSmall>
            </Box>
        </Layout>
    );
}

export default SharedPage;
